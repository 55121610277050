<template>
  <div>
    <router-link class="come-back" to="/admin/unit">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование подразделения' : 'Создание подразделения' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createConvocation" ref="unit">
      <div>
        <div class="content-sb">
          <p>Название<span>*</span></p>
          <input required placeholder="Введите название" type="text" name="name" v-model="form.name" class="input blue">
        </div>
        <div class="content-sb">
          <p>Название (Англ)</p>
          <input placeholder="Введите название (Англ)" type="text" name="nameEng" v-model="form.nameEng" class="input blue">
        </div>
        <div class="content-sb">
          <p>Приоритет</p>
          <input placeholder="Введите чилсо" type="number" name="order" v-model="form.order" class="input blue">
        </div>
      </div>

      <div class="content-sb">
        <p>Состав<span>*</span></p>
        <select required class="input blue"  ref="convocation" @change="changeConvocation" name="convocation">
          <option v-if="$route.query.type === 'create'" disabled selected>Выберите состав</option>
          <option v-for="item in $store.getters.getConvocation"
                  :selected="form.convocation === item.id"
                  :key="`getConvocation${item.id}`" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="content-sb">
        <p>Тип подразделения<span>*</span></p>
        <select required class="input blue" ref="unitType" @change="changeUnitType" name="type">
          <option v-if="$route.query.type === 'create'" disabled selected>Выберите тип подразделений</option>
          <option v-for="item in $store.getters.getUnitType"
                  :selected="form.type === item.title"
                  :key="`getUnitType${item.code}`" :value="item.code">
            {{ item.title }}
          </option>
        </select>
      </div>

      <div class="content-sb">
        <p>Родитель</p>
        <select class="input blue" ref="parent" @change="changeParent">
          <option disabled selected value="">Выберите родителя</option>
          <option value="">Нету родителя</option>
          <option v-for="item in $store.getters.getParents"
                  :selected="form.parent === item.id"
                  :key="`parent${item.id}`" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div v-for="item in form.files" :key="`oldDocuments${item.id}`">
        <br><br>
        <div class="content-sb" v-if="form.files.length && $route.query.type === 'edit'">
          <p>Название документа<span>*</span></p>
          <input required placeholder="Введите название" type="text" v-model="item.name" class="input blue">
        </div>
        <div class="content-sb" v-if="form.files.length && $route.query.type === 'edit'">
          <p>Приоритет<span>*</span></p>
          <input required placeholder="Введите чилсо" type="number" v-model="item.order" class="input blue">
        </div>
        <div v-if="$route.query.type === 'edit'" class="content-sb">
          <p>Старый документ<span>*</span></p>
          <div v-if="form.files.length">
            <div class="wrapper-documets-el">
              <a :href="item.url">Старый документ</a><br/>
              <svg title="Удалить" @click="deleteDocuments(item.id)"  viewBox="0 0 511.992 511.992"  xmlns="http://www.w3.org/2000/svg"><path d="m415.402344 495.421875-159.40625-159.410156-159.40625 159.410156c-22.097656 22.09375-57.921875 22.09375-80.019532 0-22.09375-22.097656-22.09375-57.921875 0-80.019531l159.410157-159.40625-159.410157-159.40625c-22.09375-22.097656-22.09375-57.921875 0-80.019532 22.097657-22.09375 57.921876-22.09375 80.019532 0l159.40625 159.410157 159.40625-159.410157c22.097656-22.09375 57.921875-22.09375 80.019531 0 22.09375 22.097657 22.09375 57.921876 0 80.019532l-159.410156 159.40625 159.410156 159.40625c22.09375 22.097656 22.09375 57.921875 0 80.019531-22.097656 22.09375-57.921875 22.09375-80.019531 0zm0 0" fill="#e76e54"/></svg>
            </div>
          </div>
          <p v-else>Документов нет</p>
        </div>
      </div>

      <br><br>
      <div class="file_block" v-for="(item, i) in materialsDocuments" :key="'file'+i">
        <div class="content-sb">
          <p>Название документа<span>*</span></p>
          <input required placeholder="Введите название" type="text" v-model="materialsDocuments[i].title" class="input blue">
        </div>
        <div class="content-sb">
          <p>Документ<span>*</span></p>
          <input required type="file"  class="input blue" @input="fileClick($event, i)">
        </div>
        <div class="content-sb">
          <p>Приоритет<span>*</span></p>
          <input required placeholder="Введите чилсо" type="number" v-model="materialsDocuments[i].order" class="input blue">
        </div>
        <button type="button" class="btn-red" @click="delSection(i)">Удалить документ</button>
      </div>
      <button type="button" @click="addDocument" class="btn-blue">Добавить документ</button>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/unit" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" :value="$route.query.type === 'edit' ? 'Изменить' : 'Сохранить'"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'createUser',
  data () {
    return {
      materialsDocuments: [],
      form: this.$route.query.type === 'edit' ? JSON.parse(JSON.stringify(this.$store.getters.getDetailUnit)) : {
        name: '',
        nameEng: '',
        convocation: null,
        type: null,
        parent: null,
        order: 0
      }
    }
  },
  methods: {
    createConvocation () {
      const dataFile = this.$store.getters.getDetailUnit
      for (const key in this.form.files) {
        const formFile = new FormData()
        var item = this.form.files[key]
        if (item !== '' && (item.name !== dataFile.files[key].name || item.order !== dataFile.files[key].order)) {
          formFile.append('title', item.name)
          formFile.append('order', item.order)
          this.$store.dispatch('EditDocuments', {
            formData: formFile, id: item.id, systemTagList: item.systemTagList
          })
        }
      }

      const formData = new FormData(this.$refs.unit)
      if (this.$refs.parent.value) {
        formData.append('parent', this.$refs.parent.value)
      }

      if (this.materialsDocuments !== 0) {
        for (var i in this.materialsDocuments) {
          formData.append(`materialsDocuments[${i}][title]`, this.materialsDocuments[i].title)
          formData.append(`materialsDocuments[${i}][file]`, this.materialsDocuments[i].file)
          formData.append(`materialsDocuments[${i}][order]`, this.materialsDocuments[i].order)
        }
      }

      if (this.$route.query.type === 'edit') {
        this.form.type = this.$refs.unitType.value
        this.$store.dispatch('editUnit', { id: this.form.id, data: formData })
      } else {
        this.$store.dispatch('createUnit', formData)
      }
    },
    changeConvocation (e) {
      this.$store.dispatch('setParents', e.target.value)
      this.form.convocation = e.target.value
    },
    changeUnitType (e) {
      this.form.type = e.target.value
    },
    changeParent (e) {
      this.form.parent = e.target.value
    },
    fileClick (event, i) {
      for (var item of event.target.files) {
        const file = item
        var newFile = new File([file], ` ${file.name}`, {
          type: file.type
        })
        this.materialsDocuments[i].file = newFile
      }
    },
    async deleteDocuments (id) {
      await this.$store.dispatch('deleteDocuments', id)
      const res = await fetch(`${this.$store.getters.getUrlApi}api/admin/unit/${this.form.id}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      const data = await res.json()
      this.form.files = data.data.files
    },
    addDocument () {
      this.materialsDocuments.push({
        file: null,
        title: '',
        order: 0
      })
    },
    delSection (i) {
      this.materialsDocuments.splice(i, 1)
    }
  },
  mounted () {
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailUnit === null) {
      this.$router.push('/admin/unit')
    }
    this.$store.dispatch('setParents', this.$refs.convocation.value)
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}

.wrapper-documets-el {
  display: flex;
  justify-content:space-between;
  width: 33.75rem;
  margin-bottom: 2rem;
  svg {
    width: 1rem;
    cursor: pointer;
  }
}
.file_block{
  border-bottom: 1px solid #D5D6D9;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}
</style>
